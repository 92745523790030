import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Overlays from 'tvjs-overlays'
import { PERIOD_TIMES } from './period-times'
import { GraphicTradeError } from '../../../errors'
import {
  runAtTimestamp,
  getTimezoneGMTOffset,
  calculatePercent,
} from '../../../utils'
import { DealOverlay } from './deals.overlay'

/**
 * Date index
 *
 * 1499040000000,      // Open time
 * "0.01634790",       // Open
 * "0.80000000",       // High
 * "0.01575800",       // Low
 * "0.01577100",       // Close
 * "148976.11427815",  // Volume
 * 1499644799999,      // Close time
 * "2434.19055334",    // Quote asset volume
 * 308,                // Number of trades
 * "1756.87402397",    // Taker buy base asset volume
 * "28.46694368",      // Taker buy quote asset volume
 * "17928899.62484339" // Ignore.
 */
const DT_I = {
  TIMESTAMP: 0,
  OPEN: 1,
  HIGH: 2,
  LOW: 3,
  CLOSE: 4,
  VOLUME: 5,
}

const SHOW_MARKERS_ONLY_PERIOD = [
  PERIOD_TIMES[0],
  PERIOD_TIMES[1],
  PERIOD_TIMES[2],
]

const sliceTime = t => {
  let val = Number(t.match(/\d+/)[0])

  const cc = {
    s: 1000,
    m: 60000,
    h: 3600000,
    d: 86400000,
    w: 604800000,
  }

  let c = cc[t.replace(/[0-9]/g, '')]

  const milliseconds = c * val

  return {
    millisecond: milliseconds,
    second: milliseconds / cc.s,
    minute: milliseconds / cc.m,
    hour: milliseconds / cc.m,
    day: milliseconds / cc.d,
    weak: milliseconds / cc.w,
  }
}

const MARKER_COLORS = {
  SELL: process.env.VUE_APP_MARKER_SELL_COLOR,
  BUY: process.env.VUE_APP_MARKER_BUY_COLOR,
}

export default {
  data: () => ({
    // List of times between candles
    times: PERIOD_TIMES,

    // Show trade markers on graphic
    showMarkers: true,

    // js set timeout instance for separate candles
    timeoutCron: undefined,

    // Auto scroll to last candle
    autoScroll: false,

    // Width graphic component. Default should be zero.
    // After load component will changed.
    widthChart: 0,

    // Array of quotations
    ohlcv: [],

    // Last dynamic candle on graph.
    lastOhlcv: undefined,

    // Last candle without computed property
    lastOhlcvSource: undefined,

    // Plugins for trade graphic
    overlays: [
      DealOverlay,
      // Overlays['Markers']
    ],

    // Current GMT offset
    timezoneGMT: getTimezoneGMTOffset(),

    // Source data for 24-h stats
    pastDayCandle: undefined,
  }),

  computed: {
    ...mapGetters('Finance', [
      'getterSelectedPair',
      'getterSelectedPairMarkers',
    ]),

    ...mapState('App', ['showPairStats', 'selectedPeriodCandles']),

    currentSelectedPairPrice() {
      return this.lastOhlcv?.[DT_I.CLOSE]
    },

    dayStats() {
      return {
        available: !!this.lastOhlcvSource && !!this.pastDayCandle,
        change: this.lastOhlcvSource?.close - this.pastDayCandle?.[DT_I.CLOSE],
        high: this.lastOhlcvSource?.high,
        low: this.lastOhlcvSource?.low,
        volume: this.lastOhlcvSource?.volume,
        changePercent: calculatePercent(
          this.pastDayCandle?.[DT_I.CLOSE],
          this.lastOhlcvSource?.close
        ),
      }
    },

    /**
     * Computed styles for graphic component
     *
     * @returns {Object} css styles
     */
    graphStyles() {
      return {
        opacity: this.widthChart > 0 ? 1 : 0,
      }
    },

    /**
     * Selected time in time formatter
     *
     * @returns {Object}
     */
    selectedSlicedTime() {
      return sliceTime(this.selectedPeriodCandles)
    },

    /**
     * Getter ohlcv with
     *
     * @returns {Array} of quotations
     */
    dataView() {
      let res = [...this.ohlcv]
      if (this.lastOhlcv) res.push(this.lastOhlcv)

      return res
    },

    /**
     * @returns {Object} of ohlcv. Values is timestamps
     */
    getterOhlcvByTime() {
      return this.ohlcv.reduce((sum, e) => {
        sum[e[DT_I.TIMESTAMP]] = e
        return sum
      }, {})
    },

    /**
     * Block user view markers nad change state view
     */
    disabledMarkers() {
      return !SHOW_MARKERS_ONLY_PERIOD.includes(this.selectedPeriodCandles)
    },

    /**
     * Markers getter
     *
     * @returns {Array} of objects for markers overlay plugin
     * Docs see https://github.com/tvjsx/tvjs-overlays/tree/master/src/overlays/Markers
     */
    markers() {
      if (!this.showMarkers || this.disabledMarkers) return []

      let getH = t => {
        let z = this.getterOhlcvByTime[t]
        return z ? z[DT_I.HIGH] : 0
      }

      return this.getterSelectedPairMarkers
        .map(e => [
          e.openTime,
          {
            color: e.move > 0 ? MARKER_COLORS.BUY : MARKER_COLORS.SELL,
            sel: e.move < 0,
            $: getH(e.openTime),
            text: e.move > 0 ? 'B' : 'S',
            textColor: 'white',
          },
        ])
        // .slice(0, 40)
    },

    /**
     *
     * @returns configuration object for trading vue library
     * Docs see https://github.com/tvjsx/trading-vue-js/tree/master/docs/api#data-structure-new
     */
    chart() {
      return {
        chart: {
          type: 'Candles',
          // tf: '0s',
          data: this.dataView,
          settings: {
            // Marker size only in minutes
            markerSize: this.selectedSlicedTime.minute,
            colorCandleDw: process.env.VUE_APP_CANDLE_DOWN_COLOR,
            colorCandleUp: process.env.VUE_APP_CANDLE_UP_COLOR,
            colorWickUp: process.env.VUE_APP_CANDLE_UP_COLOR,
            colorWickDw: process.env.VUE_APP_CANDLE_DOWN_COLOR,
          },
        },

        onchart: [
          {
            name: 'Deals',
            type: 'Deals',
            settings: {
              'z-index': 10,
              legend: false,
            },
            data: this.markers,
          },
        ],

        tools: [
          {
            type: 'Cursor',
            icon:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAgMAAAC5h23wAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAxQTFRFAAAATU1NTU1NTU1NwlMHHwAAAAR0Uk5TAOvhxbpPrUkAAAAkSURBVHicY2BgYHBggAByabxg1WoGBq2pRCk9AKUbcND43AEAufYHlSuusE4AAAAASUVORK5CYII=',
          },
          {
            type: 'LineToolSegment',
            icon:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAgMAAAC5h23wAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAlQTFRFAAAATU1NJCQkCxcHIQAAAAN0Uk5TAP8SmutI5AAAACxJREFUeJxjYMACGAMgNAsLdpoVKi8AVe8A1QblQlWRKt0AoULw2w1zGxoAABdiAviQhF/mAAAAAElFTkSuQmCC',
          },
          {
            type: 'LineToolExtended',
            icon:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAQMAAAD+JxcgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAATU1NkJ+rOQAAAAJ0Uk5TAP9bkSK1AAAANElEQVR4nGNggABGEMEEIlhABAeI+AASF0AlHmAqA4kzKAAx8wGQuAMKwd6AoYzBAWonAwAcLwTgNfJ3RQAAAABJRU5ErkJggg==',
          },
        ],
        tool: 'Cursor',
      }
    },
  },

  watch: {
    /**
     * Re-initialization graphic after user change pair
     */
    getterSelectedPair(newV, oldV) {
      if (newV?.name != oldV?.name) this.initGraph()
    },
  },

  mounted() {
    this.initGraph()
    window.addEventListener('resize', this.updateWithGraph)
  },

  beforeDestroy() {
    // Clear exist timeout
    clearTimeout(this.timeoutCron)

    window.removeEventListener('resize', this.updateWithGraph)
  },

  methods: {
    ...mapActions('Finance', [
      'getLastCandles',
      'getMarkersList',
      'getDayCandles',
    ]),
    ...mapMutations('App', ['UPDATE_PERIOD_CANDLES']),

    /**
     * Handle change period in select
     *
     * @param {String} value time from PERIOD_TIMES
     */
    onUpdatePeriodCandles(event) {
      this.UPDATE_PERIOD_CANDLES(event.target.value)
      this.initGraph()
    },

    /**
     * Set actual graph width
     */
    updateWithGraph() {
      this.widthChart = 0

      this.$nextTick(() => {
        this.widthChart = this.$refs?.graphicCol?.clientWidth || 0
      })
    },

    /**
     * Create timer function separate candles
     *
     * @param {String} time crontab time
     */
    createTimerSeparateCandles() {
      // Clear exist timeout
      clearTimeout(this.timeoutCron)

      const lastETimestamp = this.ohlcv[this.ohlcv.length - 1]?.[DT_I.TIMESTAMP]

      this.timeoutCron = runAtTimestamp(
        lastETimestamp + this.selectedSlicedTime.millisecond * 2,
        () => {
          if (!this.ohlcv || !this.lastOhlcv) return

          this.lastOhlcv[DT_I.TIMESTAMP] =
            this.dateToTimestampM() - this.selectedSlicedTime.millisecond

          this.ohlcv.push(this.lastOhlcv)

          this.lastOhlcv = undefined

          this.createTimerSeparateCandles()

          // if (this.autoScroll)
          //   this.$refs.chart?.goto(new Date().getTime() + 60 * 1000 * 5)
        }
      )
    },

    /**
     * Convert object of date to timestamp
     *
     * @param {Date} d
     * @returns {Number} timestamp
     */
    dateToTimestampM(d = new Date()) {
      d.setSeconds(0)
      d.setMilliseconds(0)

      return d.getTime()
    },

    /**
     * Init graphic. Get last candles from sockets.
     */
    initGraph() {
      if (!this.getterSelectedPair) return

      this.lastOhlcv = undefined
      this.lastOhlcvSource = undefined
      this.ohlcv = []

      this.getDayCandles({ pair: this.getterSelectedPair.name }).then(data => {
        this.pastDayCandle = data
      })

      this.getLastCandles({
        name: this.getterSelectedPair.name,
        period: this.selectedPeriodCandles,
      })
        .then(data => {
          this.updateWithGraph()

          if (data?.code == -1121) throw new GraphicTradeError('No found pair')

          this.getMarkersList({
            pair: this.getterSelectedPair.name,
            dateStart: data[0]?.[0] || new Date().getTime(),
            dateEnd: new Date().getTime(),
          })

          this.ohlcv = data.map(e => [
            Number(e[DT_I.TIMESTAMP]),
            Number(e[DT_I.OPEN]),
            Number(e[DT_I.HIGH]),
            Number(e[DT_I.LOW]),
            Number(e[DT_I.CLOSE]),
            0, // volume
          ])

          if (
            this.ohlcv[this.ohlcv.length - 1]?.[DT_I.TIMESTAMP] ==
            this.dateToTimestampM(new Date())
          ) {
            this.lastOhlcv = this.ohlcv.pop()
          }

          this.createTimerSeparateCandles()
        })
        .catch(err => {
          const text = err?.message
          console.error(err)

          return this.$notify({
            title: 'Error get candles data',
            type: 'error',
            text,
          })
        })
    },
  },

  sockets: {
    /**
     * Listen sockets events with actual quotations
     * @param {*} data
     */
    'mini-tickers'(data) {
      if (!this.getterSelectedPair || this.ohlcv.length == 0) return

      let d = data.list[this.getterSelectedPair.name]
      if (!d) return

      let lastE = this.ohlcv[this.ohlcv.length - 1]

      let closeNow = Number(d.close)

      let hightMarker = this.lastOhlcv ? this.lastOhlcv[2] : closeNow
      let lowMarker = this.lastOhlcv ? this.lastOhlcv[3] : closeNow

      if (this.lastOhlcv) {
        // Check hight
        if (this.lastOhlcv[2] < closeNow) hightMarker = closeNow

        // Check low
        if (this.lastOhlcv[3] > closeNow) lowMarker = closeNow
      }

      this.lastOhlcvSource = d

      this.lastOhlcv = [
        lastE[DT_I.TIMESTAMP] + this.selectedSlicedTime.millisecond,
        lastE ? lastE[DT_I.CLOSE] : d.open,
        hightMarker,
        lowMarker,
        closeNow,
        0, // Number(d.volume),
      ]
    },
  },
}
