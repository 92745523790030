export function runAtTimestamp(timestamp, cbk) {
  if (typeof timestamp != 'number')
    throw 'timestamp should be a valid Date object'

  let ms = timestamp - new Date().getTime()
  if (ms < 0) return undefined
  return setTimeout(cbk, ms)
}

export function getTimezoneGMTOffset(d = new Date()) {
  var offset = d.getTimezoneOffset()
  var sign = offset < 0 ? 1 : -1
  offset = Math.abs(offset / 60)
  return Math.abs(offset) * sign
}
