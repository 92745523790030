<template>
  <div class="trade-chart-panel">
    <template v-if="getterSelectedPair">
      <div class="header">
        <div class="trade-header container-fluid">
          <div class="row">
            <div class="col-md-4 col-sm-4 chain">
              <div class="chain__info-box">
                <div class="pair-name">
                  {{ getterSelectedPair.pair }}
                  <div class="show-pair-stats" @click="toggle">
                    <img src="./img/arrow-down.svg" alt="down" />
                  </div>
                </div>
                <div class="pair-price">
                  {{ getterSelectedPair.price | currency }}
                </div>
                <div class="total-balance" v-if="balanceStats">
                  Bot balance:
                  {{ balanceStats.now | currency }}
                </div>
              </div>
            </div>
            <div class="col-md-8 col-sm-8 status">
              <div class="options__box">
                <div
                  class="item"
                  @click="showMarkers = !showMarkers"
                  :hidden="disabledMarkers"
                >
                  <span>Markers: {{ showMarkers ? 'on' : 'off' }}</span>
                </div>
                <div class="item item-time">
                  <span>Time: </span>
                  <select
                    :value="selectedPeriodCandles"
                    @change="onUpdatePeriodCandles"
                  >
                    <option
                      :value="opt"
                      v-for="(opt, key) in times"
                      :key="key"
                      >{{ opt }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
            <div class="mobile-tab-box">
              <ul class="tab-list-item">
                <li
                  class="item"
                  v-for="(tab, index) in tabs"
                  @click="currentTab = index"
                  :class="{ active: currentTab === index }"
                  :key="index"
                >
                  {{ tab }}
                </li>
              </ul>
              <div class="mobile-content">
                <div class="tab-content">
                  <div v-show="currentTab === 1" class="bot-terminal-box">
                    <BotTerminal />
                  </div>
                  <div v-show="currentTab === 2" class="wallet">
                    <Wallet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body">
        <div class="graphic-col" ref="graphicCol" v-show="currentTab === 0">
          <trading-vue
            :style="graphStyles"
            :data="chart"
            ref="chart"
            :width="widthChart"
            :titleTxt="''"
            colorScale="#3A5777"
            colorBack="transparent"
            colorGrid="#3A5777"
            colorText="#607B9D"
            :overlays="overlays"
            :timezone="timezoneGMT"
          ></trading-vue>
        </div>
      </div>
    </template>
    <template v-else>
      <UiPreloader />
    </template>
  </div>
</template>

<script>
import TradingVue from 'trading-vue-js'
import BotTerminal from './BotTerminal.vue'
import Wallet from './Wallet.vue'

import { mapActions, mapMutations, mapState } from 'vuex'

import TradeGraphMixin from './trade-graph'

export default {
  mixins: [TradeGraphMixin],

  components: {
    TradingVue,
    BotTerminal,
    Wallet,
  },

  data() {
    return {
      isActive: true,
      currentTab: 0,
      tabs: ['Chart', 'Bot terminal', 'Indicators'],
    }
  },

  computed: {
    ...mapState('Finance', ['balanceStats']),
  },

  methods: {
    ...mapMutations('App', ['TOGGLE_USER_PAIR_STATS']),

    toggle() {
      this.TOGGLE_USER_PAIR_STATS(!this.showPairStats)
      console.log('work')
    },
  },
}
</script>
