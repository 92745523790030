<template>
  <div class="bot-terminal-panel">
    <div class="header">
      <div class="title">
        {{ $t('trate_page.bot_terminal.title') }}
      </div>
    </div>
    <div class="body">
      <div class="commands" ref="commandsRef">
        <div class="item" v-for="(item, i) in sortedListMessages" :key="i">
          <span class="time"
            >> {{ item.createdAt | luxon('dd-MM-yyyy hh:mm') }} :
          </span>
          <span>{{ $t(item.langKey, getArgs(item.langArgs)) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapActions } from 'vuex'

let listCommands = [
  'get data from exchanges',
  'complete receive data from exchanges',
  'run analysis of the received data',
  'creation of possible vectors of growth and fall of the currency',
  'choosing the best strategy',
  'creating an order on the exchange',
  'waiting for bidding',
  'processing of data on the conducted auctions',
  `launching the distribution of revenue among users' wallets`,
]

function format(text) {
  return DateTime.now().toFormat('dd-MM-yyyy hh:mm') + ': ' + text
}

// console.log('DateTime', DateTime.now())
// listCommands = listCommands.map(e => format(e))

export default {
  data: () => ({
    // listC: [...listCommands, ...listCommands],
    listMessages: [],

    // scrollInterval: undefined
  }),

  mounted() {
    // const countC = listCommands.length - 1
    // let counter = 0
    // this.scrollInterval = setInterval(() => {
    //   counter++
    //   this.listC.push(format(listCommands[counter]))
    //   if (counter == countC) counter = 0
    //   this.$nextTick(() => {
    //     this.$refs.commandsRef.scrollTop = this.$refs.commandsRef.scrollHeight
    //   })
    // }, 1300)

    this.getBotTerminalMessages().then(list => {
      this.listMessages = list
      this.scrollToDown()
    })
  },

  computed: {
    sortedListMessages() {
      return this.listMessages
        .slice()
        .sort(
          (a, b) =>
            DateTime.fromISO(a.createdAt) - DateTime.fromISO(b.createdAt)
        )
    },
  },

  sockets: {
    'bot-terminal-message'(msg) {
      this.listMessages.push(msg)
      this.scrollToDown()
    },
  },

  methods: {
    ...mapActions('App', ['getBotTerminalMessages']),

    getArgs(json) {
      return json ? JSON.parse(json) : null
    },

    scrollToDown() {
      this.$nextTick(() => {
        this.$refs.commandsRef.scrollTop = this.$refs.commandsRef.scrollHeight
      })
    },
  },

  // beforeDestroy() {
  //   clearInterval(this.scrollInterval)
  // }
}
</script>
