import { mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'

const COLORS = [
  '#0ECB81',
  '#FF3551',
  '#0D39B0',
  '#0ECB81',
  '#54EEB2',
  '#10DEAD',
  '#5B8309',
  '#2459FE',
  '#A85841',
  '#E25A3C',
  '#741D59',
  '#B4B615',
  '#CBC533',
  '#227693',
  '#FB9DE4',
  '#4ACCE5',
  '#3BDC88',
  '#739508',
  '#959498',
  '#3F0828',
  '#ECB08A',
  '#9A9FBD',
  '#BF9F85',
  '#D846D9',
  '#DAEC7A',
  '#2F2E63',
  '#109EA6',
  '#64534B',
  '#5C2170',
  '#1635E4',
]

export default {
  computed: {
    ...mapGetters('Finance', [
      'getterPairsMarkers',
      'getterPairsMarkersByLimit',
    ]),

    tableMarkersData() {
      return this.getterPairsMarkersByLimit(5).reverse()
    },

    pairToName() {
      return this.availablePairs.reduce()
    },

    chartData() {
      return {
        options: {
          colors: COLORS,
          grid: {
            show: true,
            borderColor: '#3A5777',
          },
          chart: {
            id: 'apexchart-indicators',
            foreColor: '#607B9D',
            animations: {
              enabled: true,
              easing: 'linear',
              dynamicAnimation: {
                speed: 1000,
              },
            },

            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          stroke: {
            curve: 'smooth',
            show: true,
            lineCap: 'butt',
            colors: undefined,
            width: 3,
            dashArray: 0,
          },
          legend: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },

          yaxis: {
            show: false,
          },

          xaxis: {
            labels: {
              show: false,
            },

            categories: [],

            axisBorder: {
              show: false,
            },

            axisTicks: {
              show: false,
            },
          },
        },
        series: this.series,
      }
    },

    series() {
      return Object.keys(this.getterPairsMarkers)
        .sort((a, b) => a.localeCompare(b))
        .map(key => ({
          name: key.replace('USDT', '/USDT'),
          data: this.getterPairsMarkers[key].map(e => Number(e.profit)),
        }))
    },

    walletsColors() {
      return Object.keys(this.getterPairsMarkers)
        .sort((a, b) => a.localeCompare(b))
        .reduce((sum, e, i) => {
          sum[e] = COLORS[i]
          return sum
        }, {})
    },
  },

  mounted() {
    console.log('11')

    this.getTradablePairs().then(list => {
      const dateStart = DateTime.now()
        .minus({ weeks: 1 })
        .valueOf()

      const dateEnd = new Date().getTime()


      list.forEach(e => {
        this.getMarkersList({
          pair: e.name,
          dateStart,
          dateEnd,
          limit: 9,
        })
      })
    })
  },

  methods: {
    ...mapActions('Finance', ['getMarkersList', 'getTradablePairs']),
  },
}
