<template>
  <div class="trade-pairs-panel">
    <div class="header">
      <div class="trade-search">
        <div class="trade-search__input">
          <img src="./img/search.png" alt="search" />
          <input type="search" placeholder="Search" v-model="search" />
        </div>
      </div>
    </div>
    <div class="body">
      <div class="main-box">
        <div class="main-content">
          <div class="pair">
            <span>{{ $t('trate_page.trade_pairs.pair') }}</span>
            <div class="arrows">
              <div class="arrowUp"></div>
              <div class="arrowDown"></div>
            </div>
          </div>
          <div class="price_change">
            <div class="price">
              <span>{{ $t('trate_page.trade_pairs.price') }}</span>
              <div class="arrows">
                <div class="arrowUp"></div>
                <div class="arrowDown"></div>
              </div>
            </div>
            <div class="price" hidden>
              <span>{{ $t('trate_page.trade_pairs.price') }}</span>
              <div class="arrows">
                <div class="arrowUp"></div>
                <div class="arrowDown"></div>
              </div>
            </div>
            <div class="change" hidden>
              <span>{{ $t('trate_page.trade_pairs.change') }}</span>
              <div class="arrows">
                <div class="arrowUp"></div>
                <div class="arrowDown"></div>
              </div>
              <img src="./img/arrow-sort.svg" alt="sort" />
            </div>
          </div>
        </div>
        <div class="pairs-list">
          <div
            class="item"
            v-for="(item, i) in zones"
            :key="i"
            :class="{
              tradable: item.tradable,
              selected: item.name == selectedPairName,
            }"
            @click="handleClickOnPair(item)"
          >
            <div class="pair-item">
              <img src="./img/star.png" alt="star" />
              <span class="pair-name">{{ item.pair }}</span>
            </div>
            <div class="box-values__right-side"></div>
            <div class="price-item">
              <span>{{ item.price | tradeCurrency }}</span>
              <span class="second-price" hidden>{{
                item.price | tradeCurrency
              }}</span>
            </div>
            <div class="change-item" hidden>
              <span>{{ item.change }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  data: () => ({
    search: '',
    sortBy: undefined,
    sortedTypes: {
      name: 1,
      price: 2,
    },
  }),

  computed: {
    ...mapState('Finance', [
      'futuresPrices',
      'favoritePairs',
      'tradablePairs',
      'selectedPairName',
    ]),

    zones() {
      return this.futuresPrices
        .flatMap(e => {
          if (
            this.search.length > 0 &&
            !e.name.toLowerCase().includes(this.search.toLowerCase())
          )
            return []

          return [
            {
              ...e,
              tradable: this.tradablePairs.find(f => f.name == e.name),
            },
          ]
        })
        .sort((a, b) => b.tradable - a.tradable)
    },
  },

  created() {
    this.getTradablePairs()
  },

  methods: {
    ...mapActions('Finance', ['getTradablePairs']),

    toggleFavorite(name) {
      this.TOGGLE_FAVORITE_PAIR(name)
    },

    handleClickOnPair(item) {
      this.UPDATE_SELECTED_PAIR(item.name)
    },

    ...mapMutations('Finance', [
      'TOGGLE_FAVORITE_PAIR',
      'UPDATE_SELECTED_PAIR',
    ]),
  },
}
</script>
