<template>
  <div class="wallet-panel">
    <div class="header">
      <div class="title">
        <span>{{ $t('trate_page.wallet.title') }}</span>
      </div>

      <apexchart
        type="line"
        height="250px"
        ref="chart"
        :options="chartData.options"
        :series="series"
      />
    </div>

    <div class="body">
      <div class="wallet-information">
        <table class="table-wallet">
          <thead>
            <tr>
              <!-- <td>Color</td> -->
              <td>{{ $t('trate_page.wallet.open') }}</td>
              <td>{{ $t('trate_page.wallet.type') }}</td>
              <td>{{ $t('trate_page.wallet.size') }}</td>
              <td>{{ $t('trate_page.wallet.pair') }}</td>
              <td>{{ $t('trate_page.wallet.open_price') }}</td>
              <td>{{ $t('trate_page.wallet.close') }}</td>
              <td>{{ $t('trate_page.wallet.close_price') }}</td>
              <td>{{ $t('trate_page.wallet.bot_profit') }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in tableMarkersData" :key="i">
              <!-- <td>
                <div
                  class="circle"
                  :style="{ backgroundColor: walletsColors[item.pair] }"
                ></div>
              </td> -->
              <td>
                {{
                  new Date(item.openTime).toJSON() | luxon('dd-MM-yyyy HH:mm')
                }}
              </td>
              <td>
                <div class="profit" :class="[item.move > 0 ? 'green' : 'red']">
                  {{ item.move > 0 ? 'Buy' : 'Sell' }}
                </div>
              </td>
              <td>
                {{ item.size | tradeCurrency }}
              </td>
              <td class="name">
                {{ item.pair | nameToPair(getterFuturesPrices) }}
              </td>
              <td>
                {{ item.priceOpen | currency }}
              </td>
              <td>
                {{
                  new Date(item.closeTime).toJSON() | luxon('dd-MM-yyyy HH:mm')
                }}
              </td>
              <td>
                {{ item.priceClose | currency }}
              </td>
              <td>
                <div
                  class="profit"
                  :class="[item.profit > 0 ? 'green' : 'red']"
                >
                  {{ item.profit | currency }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import IndicatorsMixin from './indicators.mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [IndicatorsMixin],

  components: {
    apexchart: VueApexCharts,
  },

  filters: {
    nameToPair: (val, currencies) => {
      return currencies[val]?.pair || name
    },
  },

  computed: {
    ...mapGetters('Finance', ['getterFuturesPrices']),
  },
}
</script>
